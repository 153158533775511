<template>
  <div>
    <v-dialog v-model="show_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ headline }}</span>
        </v-card-title>
        <v-card-text> {{ text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined text @click="toogleDialog(false)"> Annuler </v-btn>
          <v-btn
            :color="color || 'error'"
            text
            @click="
              action();
              toogleDialog(false);
            "
          >
            {{ actiontext }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    action: {
      type: Function,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    actiontext: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    color: String,
  },
  methods: {
    toogleDialog(opened) {
      // pass the event change to parent
      this.$emit("input", opened);
    },
  },
  computed: {
    show_dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>
<template>
  <v-card flat>
    <template v-if="vote">
      <v-card-title>{{ vote.title }}</v-card-title>
      <v-card-subtitle>{{ vote.description }}</v-card-subtitle>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="11">
            <v-card
              outlined
              v-for="(q, i) in vote.questions"
              :key="q._id"
              class="pa-2 mt-4"
            >
              <v-card-title>{{ i + 1 }} - {{ q.label }}</v-card-title>
              <v-card-subtitle v-if="q.description">{{
                q.description
              }}</v-card-subtitle>
              <v-card-text>
                <p v-if="q.texte" v-html="q.texte"></p>
              </v-card-text>
              <v-card-text class="text-center">
                <v-row justify="center" class="mb-2">
                  <v-col
                    v-for="ans in q.answers.filter((x) => !!x.image)"
                    :key="ans._id"
                    cols="auto"
                  >
                    <v-img
                      :src="'/img/' + ans.image"
                      max-width="200"
                      contain
                      class="d-block margin-x-auto"
                    />
                  </v-col>
                </v-row>
                <v-btn-toggle
                  v-model="form[q._id]"
                  borderless
                  shaped
                  style="flex-direction: column"
                  color="indigo darken-4"
                  :rules="[(v) => v || false]"
                >
                  <v-btn
                    v-for="ans in q.answers"
                    :key="ans._id"
                    :value="ans"
                    style="border-bottom: 1px solid black"
                    class="d-block py-2"
                  >
                    {{ ans.label }}
                  </v-btn>
                </v-btn-toggle>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="11" class="mt-0 mb-8">
            <v-btn
              class="float-right"
              color="green"
              :disabled="!formValid"
              style="transition: background-color 0.5s ease"
              @click="confirm = true"
            >
              Valider
            </v-btn>
            <ConfirmDialog
              headline="Envoyer?"
              text="Toute validation est définitive ! Vous ne pourrez plus modifier votre vote."
              actiontext="Confirmer"
              :action="send"
              color="green"
              v-model="confirm"
            >
            </ConfirmDialog>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <v-dialog v-model="loading" dense persistent width="67">
      <v-card class="pa-4 text-center">
        <v-progress-circular indeterminate class="pa-3"></v-progress-circular>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  name: "Vote",
  components: { ConfirmDialog },
  data() {
    return {
      form: {},
      confirm: false,
      loading: true,
      // DATA
      vote: undefined,
    };
  },
  mounted() {
    this.axiosPost(process.env.VUE_APP_API_URL + "/api/getVote", {
      id: this.id,
    })
      .then((resp) => {
        this.vote = resp.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    setInfo(q, a) {
      this.form[q] = a;
    },
    send() {
      this.loading = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/api/vote", {
        id: this.id,
        form: this.form,
      })
        .then(() => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    formValid() {
      if (!this.vote) return false;
      for (let q of this.vote.questions) {
        if (!this.form[q._id]) {
          return false;
        }
      }
      return true;
    },
    id() {
      return this.$route.query.id;
    },
  },
};
</script>